/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: 'Noto Sans';
  src: url(./assets/fonts/NotoSans-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Noto Sans';
  src: url(./assets/fonts/NotoSans-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: 'Noto Sans';
  src: url(./assets/fonts/NotoSans-Italic.ttf);
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Noto Sans';
  src: url(./assets/fonts/NotoSans-BoldItalic.ttf);
  font-style: italic;
  font-weight: 700;
}

:root {
  --font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol;
  --font-family: var(--font-noto-sans);
  --font-noto-sans-bold: 'Noto Sans Bold';
  --font-noto-sans-italic: 'Noto Sans Italic';
  --font-noto-sans-bold-italic: 'Noto Sans Bold Italic';
  --font-noto-sans-regular: 'Noto Sans';
}

h5,
h3,
h1,
p,
table,
td,
tr,
form,
button,
a,
div {
  font-family: var(--font-noto-sans-regular);
}

.font-noto-sans {
  font-family: var(--font-noto-sans-regular);
}

.font-noto-sans-bold {
  font-family: var(--font-noto-sans-bold);
  font-weight: 700;
}

.font-noto-sans-italic {
  font-family: var(--font-noto-sans-italic);
  font-style: italic;
}

.font-noto-sans-bold-italic {
  font-family: var(--font-noto-sans-bold-italic);
  font-weight: 700;
  font-style: italic;
}

.shepherd-element {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3) !important;
  z-index: 100 !important;
}

.shepherd-button {
  background-color: #d91e18 !important;
  color: #ffffff !important;
  border-radius: 2rem !important;
}

.shepherd-button-secondary {
  background-color: #ffffff !important;
  color: #d91e18 !important;
  border: 1px solid #d91e18 !important;
}

/* Keep overlay styles as they are, but add these for active steps */
.shepherd-element {
  z-index: 9999 !important;
  background: white !important; /* or any color you want */
  pointer-events: auto !important;
}

